import { ReactSVG } from 'react-svg'

type IReactSvgProp = {
  sourceSvg: string
  width?: number
  height?: number
}
export const ReactSvg = ({ sourceSvg, width, height }: IReactSvgProp) => (
  <ReactSVG src={sourceSvg} width={`${width}px` || 'auto'} height={`${height}px` || 'auto'} />
)
