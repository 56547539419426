import {globalColors} from "../../../../../data/colors"

export const signUpCongradulate = {
  token: {
    inherit: true,
    bgColor: globalColors.lightGrayBg,
    borderRadius: 14,
    colorBgBase: globalColors.white,
    colorPrimary: globalColors.purple,
    paddingLG: 80,
  },
  components: {
    Button: {
      algorithm: true,
      controlHeight: 50,
    },
  },
}
