import { signUpCongradulate } from 'containers/app/screens/SignUp/data/theme/signUp/signUpCongradulate'
import { Button, Card, Col, Layout, Row, Typography } from 'antd'
import Logo from 'assets/reactIcons/Logo'
import { ReactSvg } from 'assets/reactIcons/ReactSvg'
import { useScreenSize } from 'containers/app/hooks/useScreenSize'
import Congradulation from 'assets/images/congradulations.svg'
import React from 'react'
import { ArrowRightOutlined } from '@ant-design/icons'
import { CABINET_ROOT } from 'constants/routes.constants'
import { Header } from 'containers/shared/components/typography/Header'
import { Text } from 'containers/shared/components/typography/Text'
import { ThemeProvider } from '../../components/providers/ThemeProvider'
import styles from './style.module.scss'
const { Content } = Layout

const Congradulate = () => {
  const { breakpoint } = useScreenSize()

  const redirectHandler = () => {
    window.location.href = CABINET_ROOT
  }

  return (
    <ThemeProvider theme={signUpCongradulate}>
      <Layout style={{ minHeight: '100vh' }}>
        <Content>
          <Row
            justify='center'
            align='middle'
            style={{ minHeight: '100vh', flexDirection: 'column' }}
            gutter={[15, 15]}
          >
            <Col span={24} flex='1 1 80%' style={{ width: '100%' }} className={styles.content}>
              <Row justify='center' align='middle' style={{ padding: 20 }}>
                <Card
                  size={breakpoint !== 'desktop' ? 'small' : 'default'}
                  style={{
                    maxWidth: '720px',
                    width: '95%',
                  }}
                >
                  <Row>
                    <Col span={24} className={styles.iconMb}>
                      <Row justify='center'>
                        <ReactSvg sourceSvg={Congradulation} />
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Row justify='center'>
                        <Header size='l' level={1}>
                          Congratulations!
                        </Header>
                      </Row>
                    </Col>
                    <Col span={24} className={styles.textMb}>
                      <Row justify='center'>
                        <Typography.Text type='secondary'>You can now customize your app</Typography.Text>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Row justify='center'>
                        <Button
                          block
                          size={breakpoint !== 'desktop' ? 'small' : 'large'}
                          type='primary'
                          onClick={redirectHandler}
                        >
                          <Row justify='center' align='middle'>
                            <Text className={styles.nextButtonContent} size={breakpoint !== 'desktop' ? 's' : 'l'}>
                              Get started
                            </Text>
                            <ArrowRightOutlined style={{ fontSize: '26px' }} />
                          </Row>
                        </Button>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </Row>
            </Col>
            <Col span={24} flex='0 0 70px'>
              <Row justify='center'>
                <Logo />
              </Row>
            </Col>
          </Row>
        </Content>
      </Layout>
    </ThemeProvider>
  )
}

export default Congradulate
